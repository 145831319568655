/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
ion-content{
    --background:#ffffff;
}


ion-tab-bar{
  --background: #0ba877 ;
  padding:10px 0;
}
ion-row {
 --background :#f1f1f1;
}

ion-button{
  font-size: 15px;
  // --background:   #1fb265;
}


.desktop-wrapper {
  ion-toolbar {
    text-align: center;
  }
  
  ion-header {
    &.header-md:after {
      background: none;
    }
  }
}
:root[mode=ios],
:root[mode=md]{
  --ion-font-family:  'Open Sans', sans-serif;
  font-family:  'Open Sans', sans-serif;
}

.action-sheet-group  {
  max-height:60% !important;
}
.action-sheet-button.sc-ion-action-sheet-ios{
  font-size: 16px;
}
