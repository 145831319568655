// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/
/** Ionic CSS Variables **/
:root {
  --ion-background-color: #ffffff;
  --ion-background-color-rgb: 255,255,255;

  --ion-text-color: var(--ion-color-dark);
  --ion-text-color-rgb: 20, 26, 31;

  --ion-color-step-50: #f2f2f2;
  --ion-color-step-100: #e6e6e6;
  --ion-color-step-150: #d9d9d9;
  --ion-color-step-200: #cccccc;
  --ion-color-step-250: #bfbfbf;
  --ion-color-step-300: #b3b3b3;
  --ion-color-step-350: #a6a6a6;
  --ion-color-step-400: #999999;
  --ion-color-step-450: #8c8c8c;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #737373;
  --ion-color-step-600: #666666;
  --ion-color-step-650: #595959;
  --ion-color-step-700: #4d4d4d;
  --ion-color-step-750: #404040;
  --ion-color-step-800: #333333;
  --ion-color-step-850: #262626;
  --ion-color-step-900: #191919;
  --ion-color-step-950: #0d0d0d;
  
  /** primary **/
  --ion-color-primary: #0ba877;
  --ion-color-primary-rgb:11, 168, 119;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #09865f;
  --ion-color-primary-tint: #3cb992;

  /** secondary **/
  --ion-color-secondary: #45567c;
  --ion-color-secondary-rgb: 69, 86, 124;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #3e4d70;
  --ion-color-secondary-tint: #7d89a3;
  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #08855e;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #259b75;
  --ion-color-success-tint: #259b75;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #182d50;
  --ion-color-dark-rgb: 20, 26, 31;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #182d50;
  --ion-color-dark-tint: #182d50;

  /** medium **/
  --ion-color-medium: #6a768f;
  --ion-color-medium-rgb: 84, 98, 115;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #7d89a3;
  --ion-color-medium-tint: #7d89a3;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --background-checked: #0ba877 !important;
}


@media (prefers-color-scheme: dark ) {
  // TODO:Dark theme will be studied
  /*
   * Dark Colors
   * -------------------------------------------
   */

}

/* Fallback for older browsers or manual mode */
  body.dark {

  /* Dark mode variables go here */
  --ion-color-primary: #0ba877;
  --ion-color-primary-rgb:11, 168, 119;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #09865f;
  --ion-color-primary-tint: #3cb992;

  --ion-color-secondary: #50c8ff;
  --ion-color-secondary-rgb: 80, 200, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #46b0e0;
  --ion-color-secondary-tint: #62ceff;

  --ion-color-tertiary: #6a64ff;
  --ion-color-tertiary-rgb: 106, 100, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #5d58e0;
  --ion-color-tertiary-tint: #7974ff;

  --ion-color-success: #0ba877;
  --ion-color-success-rgb: 11, 168, 119;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #29c467;
  --ion-color-success-tint: #44e283;

  --ion-color-warning: #ffd534;
  --ion-color-warning-rgb: 255, 213, 52;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0bb2e;
  --ion-color-warning-tint: #ffd948;

  --ion-color-danger: #ff4961;
  --ion-color-danger-rgb: 255, 73, 97;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #e04055;
  --ion-color-danger-tint: #ff5b71;

  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244, 245, 248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0, 0, 0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;

  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  --ion-color-light: #222428;
  --ion-color-light-rgb: 34, 36, 40;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;

  --background-checked: #0ba877 !important;
}

ion-toolbar{
  --background: #0ba877 ;
  color:var(--ion-background-color);
  padding: 15px 0;
  letter-spacing: 2px;
}

ion-header ion-toolbar:first-of-type{
  padding-top: 15px;
}

ion-card{
  margin: 10px !important;
}

ion-item{
  --padding-start:0px ;
}

ion-item-divider ion-label{
  color:var(--ion-color-success) !important;
  font-size: 15px !important;

}

ion-item-divider{
  --padding-start:10px;
}

dynamic-ionic-form  ion-item{
  padding-left: 10px;
  font-size: 15px;
}

.bg-ion-item-color{
  background-color: #f1f1f1;

}

.native-input.sc-ion-input-ios::placeholder,
.alert-button.sc-ion-alert-ios{
  font-size: 14px;
}

.text-color-success{
  color: var(--ion-color-success);
}

.alert-title.sc-ion-alert-ios,
.alert-title.sc-ion-alert-md
{
  color:var(--ion-color-success);
  font-size: 15px;
  font-weight: 500;
}

ion-list ion-item{
  padding-left: 10px;
  font-size: 15px;
}

.alert-radio-label.sc-ion-alert-md,
.alert-checkbox-label.sc-ion-alert-md,
.card-content-ios{
  font-size: 14px;
}

p{
  font-size: 15px;
}



